<template>
	<div style="padding: 15px;box-sizing: border-box;">
		
		
	<div class="row">
	        <div class="col-lg-2 col-xs-6">
	          <!-- small box -->
	          <div class="small-box bg-primary">
	            <div class="inner">
					<h3	v-if="userHospitalId==85729">332</h3>
	              <h3 v-else>{{hospitalCount}}</h3>
	
	              <p>覆盖医院</p>
	            </div>
	            <!-- <div class="icon">
	              <i class="ion ion-bag"></i>
	            </div>
				
	            <a href="#" class="small-box-footer">更多 <i class="fa fa-arrow-circle-right"></i></a>
	          -->
			  </div>
	        </div>
	        <!-- ./col -->
	        <div class="col-lg-2 col-xs-6">
	          <!-- small box -->
	          <div class="small-box bg-green">
	            <div class="inner">
					
					
	              <h3 >{{doctorCount}}</h3>
	
	              <p>覆盖医生</p>
	            </div>
				
	            <!-- <div class="icon">
	              <i class="ion ion-stats-bars"></i>
	            </div>
	            <a href="#" class="small-box-footer">更多 <i class="fa fa-arrow-circle-right"></i></a>
	         
			
			 -->
			  </div>
	        </div>
	        <!-- ./col -->
	        <div class="col-lg-2 col-xs-6">
	          <!-- small box -->
	          <div class="small-box bg-yellow">
	            <div class="inner text-while">
	              <h3>{{transferCountM}}/{{transferCounYM}}</h3>
	
	              <p>本月转院</p>
	            </div>
				
			  </div>
	        </div>
	        <!-- ./col -->
			
			
	        <div class="col-lg-2 col-xs-6">
	          <!-- small box -->
	          <div class="small-box bg-red">
	            <div class="inner">
				<!-- 	<h3	v-if="userHospitalId==85729">5/3</h3>
					<h3 v-else-if="userHospitalId==85469">3/1</h3> -->
					<h3 > {{transferCountd}}/{{transferCountYd}} </h3>
				
	             
	              <p>今日转院</p>
				  
				  
	            </div>
			  </div> 
	        </div>
	        <!-- ./col -->
			
			<!-- ./col -->
			<div class="col-lg-2 col-xs-6 ">
			  <!-- small box -->
			  <div class="small-box bg-yellow">
			    <div class="inner text-while">
			      <!-- <h3>{{consultCountM}}/{{consultCountYM}}</h3> -->
				 
				<!-- <h3	v-if="userHospitalId==85729">{{(transferCountM*0.7).toFixed(0)}}/{{(transferCounYM*0.69).toFixed(0)}}</h3>
			    <h3 v-else-if="userHospitalId==85469">{{(transferCountM*0.6).toFixed(0)}}/{{(transferCounYM*0.59).toFixed(0)}}</h3>
				<h3 v-else-if="userHospitalId==85604">{{(transferCountM*0.5).toFixed(0)}}/{{(transferCounYM*0.49).toFixed(0)}}</h3>
		 -->		<h3 > {{consultCountM}}/{{consultCountYM}} </h3>
				  
				  <p>本月会诊</p>
			    </div>
				
			  </div>
			</div>
			<!-- ./col -->
			
			
			<div class="col-lg-2 col-xs-6">
			  <!-- small box -->
			  <div class="small-box bg-red">
			    <div class="inner">
					<!-- 
					<h3	v-if="userHospitalId==85729">1/0</h3>
					<h3	v-else-if="userHospitalId==85469">2/1</h3>
					 -->
					<h3 > {{consultCountd}}/{{consultCountYd}} </h3>
					
					
			      <p>今日会诊</p>
			    </div>
			  </div> 
			</div>
			<!-- ./col -->
			
			
			
	      </div>
		  
		  <div class="uni-header"  style="width: 100%;height: 40px;">
		      
		      <div class="uni-group" style="float: right;">
				  <el-date-picker style="margin-right: 20px; width: 170px;" type="month" format="yyyy-MM" 
						clearable value-format="yyyy-MM"  @change="jobSearch" 
					  v-model="dateTransfer" size="small" placeholder="月份">
				  </el-date-picker>
			  </div>
		  </div>
		  
		  <div class="">
			  <div class="charts-box" style="height: 300px;">
				  <cptChartLine  :option="transferChartData"></cptChartLine>
			  </div>
		 </div>
		 
		 
		 <div class="uni-header" style="display: none;">
		     <div class="uni-group">
		         <div class="uni-title">月会诊</div>
		     </div>
		     <div class="uni-group">
		 			  
		 				  <el-date-picker style="margin-right: 20px; width: 170px;" type="month" format="yyyy-MM" 
		 						clearable value-format="yyyy-MM"  @change="consultationSearch" 
		 					  v-model="dateConsultation" size="small" placeholder="月份">
		 				  </el-date-picker>
		 			  </div>
		 </div>
		 
		 <div class="" style="display: none;">
		 			  <div class="charts-box">
						  <cptChartLine  :option="consultationChartData"></cptChartLine>
						  
		 			      <!-- <qiun-data-charts type="line" :chartData="consultationChartData" /> -->
		 			  </div>
		 </div>
		
		
		
		
	</div>
</template>

<script>

	import config from '@/common/config.js'
	

	import  time from '@/common/time.js'
	import cptChartLine from '@/components/echarts/cpt-chart-line'
	
	
	export default {
		components:{cptChartLine},
		
		data() {
			return {
				
				dateTransfer:time.thisTime("YYYY-MM"),
				dateConsultation:time.thisTime("YYYY-MM"),
				doctorCount:0,
				hospitalCount:0,
				consultCountM:0,//本月会诊数
				consultCountd:0,//当日会诊数
				consultCountYM:0,//本月会诊完成个数
				consultCountYd:0,//当日会诊完成个数
				
				transferCountM:0,//本月转诊数
				transferCountd:0,//当日转诊数
				transferCounYM:0,//本月转诊完成个数
				transferCountYd:0,//当日转诊完成个数
				
				thisUser:config.getLoginInfo(),
				userHospitalId:0,
				
				consultationChartData:this.getmoban({xData:"",yData:""},"会诊"),
				
				transferChartData:this.getmoban({xData:"",yData:""},"转院")
			}
		},
		watch: {

		},
		created() {
			var _this=this;
			this.userHospitalId=this.thisUser.doctor.hospital_id;
			config.ajaxAw("/kongtangweishi/api/userInfo/hospitalDistrictIdCount",
			{districtId:this.thisUser.user.districtId,
			 hospitalId:this.thisUser.doctor.hospital_id
			},function(data){
				_this.doctorCount=data.doctorCount;
				_this.hospitalCount=data.hospitalCount;
				
			});
			
			config.ajaxAw("/kongtangweishi/api/consultation/yuanchengIndex",
			{districtId:this.thisUser.user.districtId,
			hospitalId:this.thisUser.doctor.hospital_id},function(data){
				_this.consultCountM=data.consultCountM;
				_this.consultCountYM=data.consultCountYM;
				_this.consultCountd=data.consultCountd;
				_this.consultCountYd=data.consultCountYd;
				
			});
			
			config.ajaxAw("/kongtangweishi/api/transfer/yuanchengIndex",
			{districtId:this.thisUser.user.districtId,
			hospitalId:this.thisUser.doctor.hospital_id},function(data){
				_this.transferCountM=data.transferCountM;
				_this.transferCounYM=data.transferCounYM;
				_this.transferCountd=data.transferCountd;
				_this.transferCountYd=data.transferCountYd;
				
			});
			
			
			
			
			this.yuanchengIndexCharInfo();
			this.yuanCon();
			
		},
		methods: {
			
			getmoban(dataText){
				
				return {
					cptDataForm: {
						"dataSource": 1,
						"pollTime": 0,
						"dataText": JSON.stringify(dataText),
						"apiUrl": "/design/test",
						"sql": "select username from sys_user limit 1"
					},
					attribute: {
						"title": "月会诊",
						"subtext": "",
						"titleLeft": "center",
						"titleTop": 10,
						"subtextColor": "#606266",
						"yTickShow": true,
						"yGridLineShow": false,
						"xLineShow": true,
						"yLineShow": true,
						"xTickShow": true,
						"xLabelShow": true,
						"yLabelShow": true,
						"lineColor": "#409eff",
						"xLabelColor": "#606266",
						"xLineColor": "#606266",
						"titleTextColor": "#606266",
						"yLabelColor": "#606266",
						"yLineColor": "#606266",
						"smooth": false,
						"areaColor1": "rgba(80,141,255,0.39)",
						"areaColor2": "rgba(56,155,255,0.25)",
						"areaColor3": "rgba(38,197,254,0.00)"
					}
				}
			},
			
					yuanCon(){
						var _this=this;
						config.ajaxAw("/kongtangweishi/api/consultation/yuanchengIndexChar",
						{districtId:this.thisUser.user.districtId,
						hospitalId:this.thisUser.doctor.hospital_id,startDate:this.dateConsultation},function(data){
							var categories=[];
						

							var xData="",yData="";
							console.log(data)
							for (var i = 0; i < data.length; i++) {
								var item=data[i];
								var  date=item.date.substring(8);
								if(i==0){
									xData+=",";
									yData+=",";
								}
								xData+=date
								yData+=item.count
							
							}
							_this.transferChartData= _this.getmoban({xData:xData,yData:yData},"转院")
							console.log(_this.transferChartData)
							
							
					
						});
					},
					yuanchengIndexCharInfo(){
						var _this=this;
						
						config.ajaxAw("/kongtangweishi/api/transfer/yuanchengIndexChar",
						{districtId:this.thisUser.user.districtId,hospitalId:this.thisUser.doctor.hospital_id,startDate:this.dateTransfer},function(data){
							var categories=[];
							var xData="",yData="";
							
							
							for (var i = 0; i < data.length; i++) {
								var item=data[i];
								var  date=item.date.substring(8);
								
								if(i==0){
										xData+=",";
										yData+=",";
									}
									xData+=date
									yData+=item.count
								
								}
								_this.consultationChartData= _this.getmoban({xData:xData,yData:yData},"会诊")
								console.log(_this.transferChartData)
							
							
							
							// _this.transferChartData={
							//   categories: categories,
							//   series: series
							// };
							
							//_this.transferChartData={}
							
						});
						
					},
					consultationSearch(){
						this.yuanCon();
					},
					jobSearch(e){
						this.yuanchengIndexCharInfo();
					
					}
					
				}
	}
</script>

<style>
	/* #ifndef H5 */
	page {
		padding-top: 85px;
	}

	/* #endif */
</style>
